/* Stilisiert den gesamten Scrollbar */
::-webkit-scrollbar {
    width: 10px; 
}

/* Stilisiert den Hintergrund der Scrollbar (Track) */
::-webkit-scrollbar-track {
    background: #141D2F; 
}

/* Stilisiert den beweglichen Teil der Scrollbar (Thumb) */
::-webkit-scrollbar-thumb {
    background: #00BEE7; 
}

/* Ändert die Farbe des beweglichen Teils beim Überfahren mit der Maus */
::-webkit-scrollbar-thumb:hover {
    background: #4B47FF; 
}


body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
app-main-content{
    width: 100% ;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
app-header{
    background-color: #141D2F;
    width: 100%;
    position: fixed;
    z-index: 10;

}
app-main-page{
    background-color: #141D2F; 
    display: flex;
    flex-direction: column;
}
app-mobile-menu{
    display: none;

    @media (max-width:770px){
        display: unset;
    }
}


.d-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    background-color: #00BEE7 !important;
    width: 165px;
    height: 58px !important;
    font-size: 23px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    transition: 150ms ease-in-out !important;
    width: auto;
    border-radius: 10px !important;
    &:hover {
        transform: scale(1.1);
        background-color: #4B47FF !important;
        font-weight: 600;
    }
    @media (max-width:510px) {
        font-size: 19px;
    }
}
.links {
    color: white;
    text-decoration: none;
    font-size: 23px;
    font-weight: 400;
    margin-left: 29px;
    cursor: pointer;
    position: relative; // Für das Pseudo-Element

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -5px; // Anpassung für die Position unter dem Link
        left: 0;
        background-color: #4B47FF;
        transition: width 0.3s ease-in-out;
        visibility: hidden;
    }

    &:hover {
        color: #00BEE8 !important;

        &::after {
            visibility: visible;
            width: 100%;
        }
    }
}

.contacts-icon-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-left: 40px;

    img{
        height: 32px;
        transition: all 150ms ease-in-out;
        cursor: pointer;
        &:hover { 
            transform: scale(1.3);
        }
    }

}

p{
    @media(max-width:840px){
        font-size: 56px !important;
    }
    @media(max-width:400px){
        font-size: 52px !important;
    }
}

html, body { height: 100%; scroll-behavior: smooth;}
body { margin: 0; font-family: 'Poppins', sans-serif !important; }
